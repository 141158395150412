import * as React from 'react';
import i18n from '../../i18n';
import { I18nextProvider } from 'react-i18next';
import { MultiOfferings } from '../MultiOfferings/MultiOfferings';
import { OfferingDomain } from '../../domain/offering-domain';
import {
  getDisplayOptions,
  OfferingListWidgetDisplayOptions,
} from '../../display-options/offering-list-widget-display-options';
import {
  MOBILE_MULTI_OFFERINGS_MIN_WIDTH,
  MULTI_OFFERINGS_MIN_WIDTH,
} from './WidgetApp.const';
import {
  BiLoggerContext,
  BiLoggerContextProvider,
} from '../context/bi-logger-context';
import { BiLoggerDriver } from '../../adapters/reporting/bi-logger/bi-logger-driver';
import { ISantaProps } from '@wix/native-components-infra/dist/es/src/types/types';
import { OfferingCategoryDto } from '@wix/bookings-uou-domain';
import EmptyStateView from '../EmptyStateView/EmptyStateView';
import { NavigationDriver } from '../../platform/navigation/navigation-driver';
import UserMessage from '@wix/bookings-viewer-ui/dist/src/components/UserMessage/UserMessage';
import {
  OfferingCallbacksContext,
  OfferingCallbacksProvider,
} from '../context/offering-callbacks-context';
import {
  RunningEnvironmentContext,
  RunningEnvironmentContextProvider,
} from '../context/running-environment-context';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { ExperimentsContextProvider } from '../../../Shared/context/experiments-context';
import * as _ from 'lodash';
import { isMobileFromFormFactor } from '../../../Shared/utils';
import {
  BusinessInfo,
  CatalogServiceDto,
  ServiceLocation,
} from '@wix/bookings-uou-types/dist/src';

export enum WidgetAppViewMode {
  PAGE,
  WIDGET,
}

interface WidgetViewerMainProps extends WidgetAppProps {
  host: ISantaProps;
}

export interface PlatformContext {
  isDummyMode: boolean;
  isRTL: boolean;
  isEditorMode: boolean;
  isPreviewMode: boolean;
  isSSR: boolean;
  isSEO: boolean;
}

interface WidgetAppProps {
  widgetViewMode: WidgetAppViewMode;
  offerings: CatalogServiceDto[];
  categories: OfferingCategoryDto[];
  locations: ServiceLocation[];
  selectedCategories: string[];
  businessInfo: BusinessInfo;
  locale: string;
  regionalSettings: string;
  translations: any;
  settingsData: any;
  setContainerHeight: Function;
  onCategoryChanged: Function;
  setPropsInPreviewOOI: Function;
  setContainerWidth: Function;
  setContainerDimensions: Function;
  biLoggerDriver: BiLoggerDriver;
  navigationDriver: NavigationDriver;
  canReportLoading: boolean;
  userMessage?: {
    shouldDisplayMessage: boolean;
    closeMessage: Function;
  };
  platformContext: PlatformContext;
  appLoadedCallback: Function;
  experiments: any;
  scale: number;
  isCalendarPageInstalled?: boolean;
}

export class WidgetApp extends React.PureComponent<WidgetViewerMainProps> {
  private isComponentLoaded = false;
  private readonly widgetAppRef;
  private isExecuteOnLoadedRequired = true;

  constructor(props) {
    super(props);
    this.widgetAppRef = React.createRef();
    this.updateDimensions = _.debounce(this.updateDimensions, 50);
    this.onCategorySelected = this.onCategorySelected.bind(this);
  }

  getRef() {
    return this.widgetAppRef;
  }

  private getMultiOfferingsHeight(): number {
    return _.get(this.getRef().current.getClientRects(), '[0].height', 0);
  }

  private getMultiOfferingsWidth(): number {
    return _.get(this.getRef().current.getClientRects(), '[0].width', 0);
  }

  private isRendered(): boolean {
    return (
      this.getRef().current && this.getRef().current.getClientRects().length
    );
  }

  getHostDimensions() {
    return _.get(this.props, 'host.dimensions');
  }

  private updateDimensions() {
    if (this.isRendered()) {
      const { setContainerWidth } = this.props;

      const servicesWidgetMinWidth = isMobileFromFormFactor(this.props)
        ? MOBILE_MULTI_OFFERINGS_MIN_WIDTH
        : MULTI_OFFERINGS_MIN_WIDTH;

      this.updateHeightIfNeeded();

      if (this.getMultiOfferingsWidth() < servicesWidgetMinWidth) {
        setContainerWidth(servicesWidgetMinWidth);
      }
    }
  }

  private onCategorySelected(selectedCategorySlug) {
    this.updateHeightIfNeeded();
    if (
      this.props.experiments[
        'specs.bookings.ServiceListPageCategoryUpdateUrl'
      ] === 'true'
    ) {
      setTimeout(() => this.props.onCategoryChanged(selectedCategorySlug), 500);
    }
  }

  private updateHeightIfNeeded() {
    setTimeout(() => {
      const { height } = this.getHostDimensions();

      if (height !== this.getMultiOfferingsHeight()) {
        this.props.setContainerHeight(this.getMultiOfferingsHeight());
      }
    }, 0);
  }

  shouldDisplayOfferings() {
    const { offerings } = this.props;
    return offerings.length > 0;
  }

  componentDidUpdate() {
    this.updateDimensions();
    this.executeOnLoadedCallbacks();
  }

  componentDidMount() {
    this.updateDimensions();
    this.isComponentLoaded = true;
    this.executeOnLoadedCallbacks();
  }

  executeOnLoadedCallbacks() {
    const { offerings, biLoggerDriver, canReportLoading, appLoadedCallback } =
      this.props;
    if (
      canReportLoading &&
      this.isExecuteOnLoadedRequired &&
      this.isComponentLoaded
    ) {
      this.isExecuteOnLoadedRequired = false;
      biLoggerDriver.sendViewerOpened(offerings.length);
      appLoadedCallback();
      this.props.setPropsInPreviewOOI();
    }
  }

  createOfferingsDomainInstance = (experiments) => {
    return this.props.offerings
      ? this.props.offerings.map((offering) => OfferingDomain.fromDto(offering))
      : [];
  };

  render() {
    const {
      locale,
      regionalSettings,
      translations,
      businessInfo,
      settingsData,
      biLoggerDriver,
      navigationDriver,
      categories,
      locations,
      selectedCategories,
      userMessage,
      offerings,
      platformContext,
      experiments,
      scale,
      widgetViewMode,
      isCalendarPageInstalled,
    } = this.props;

    const dimensions = this.getHostDimensions();
    const isMobile = isMobileFromFormFactor(this.props);
    const isMultiLocationEnabled =
      experiments['specs.bookings.UoUMultiLocationV1'] === 'true';
    const shouldListServicesFromOtherCategoriesForSeo =
      widgetViewMode === WidgetAppViewMode.PAGE &&
      experiments['specs.bookings.ListServicesFromOtherCategoriesForSeo'] ===
        'true';
    const displayOptions: OfferingListWidgetDisplayOptions = getDisplayOptions(
      settingsData,
      isMobile,
      isMultiLocationEnabled,
    );

    const offeringsDomain = this.createOfferingsDomainInstance(experiments);

    const biLoggerContext: BiLoggerContext = {
      biLoggerDriver,
    };

    const offeringCallbacksContext: OfferingCallbacksContext = {
      offeringCallbacks: {
        onOfferingSelected: (offeringId, intent, locationId) => {
          return navigationDriver.navigateToApp(
            offerings.find((offering) => offering.id === offeringId),
            intent,
            locationId,
          );
        },
      },
    };

    const runningEnvironmentContext: RunningEnvironmentContext = {
      isMobile,
      dimensions,
      scale,
      locale,
      regionalSettingsLocale: regionalSettings,
      isCalendarPageInstalled: !!isCalendarPageInstalled,
      ...platformContext,
    };

    return (
      <div ref={this.widgetAppRef}>
        <I18nextProvider i18n={i18n(locale, translations)}>
          <BiLoggerContextProvider value={biLoggerContext}>
            <OfferingCallbacksProvider value={offeringCallbacksContext}>
              <ExperimentsContextProvider value={{ experiments }}>
                <RunningEnvironmentContextProvider
                  value={runningEnvironmentContext}
                >
                  <TPAComponentsProvider value={{ mobile: isMobile }}>
                    <UserMessage
                      isOpen={userMessage?.shouldDisplayMessage || false}
                      onRequestClose={userMessage?.closeMessage || (() => {})}
                      isMobile={isMobile}
                      message={translations['user-message.not-bookable']}
                      okLabel={translations['user-message.action-ok-label']}
                    />
                    {this.shouldDisplayOfferings() ? (
                      <MultiOfferings
                        offeringsDomain={offeringsDomain}
                        businessInfo={businessInfo}
                        displayOptions={displayOptions}
                        categories={categories}
                        locations={locations}
                        initialedCategories={selectedCategories}
                        onCategoryChanged={this.onCategorySelected}
                        shouldListServicesFromOtherCategoriesForSeo={
                          shouldListServicesFromOtherCategoriesForSeo
                        }
                      />
                    ) : (
                      <EmptyStateView />
                    )}
                  </TPAComponentsProvider>
                </RunningEnvironmentContextProvider>
              </ExperimentsContextProvider>
            </OfferingCallbacksProvider>
          </BiLoggerContextProvider>
        </I18nextProvider>
      </div>
    );
  }
}
